import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { Press } from '../common';

export default function AddPressForm() {
    const [edited, setEdited] = useState<Press>({
        id: '',
        publish_date: '',
        title: '',
        publisher: '',
        link: ''
    });

    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            const resp = await fetch('/api/press', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(edited)
            });
            if (resp.ok) {
                navigate('/press', { replace: true });
            } else {
                alert('Error adding press entry');
            }
        } catch {
            alert('Error adding press entry');
        }
    };

    const handleChange = (name: keyof Press, val: string) => {
        setEdited((prevPress) => ({
            ...prevPress,
            [name]: val
        }));
    };

    return (
        <div className="bg-black min-h-screen">
            <Header title="Add New Press Entry" />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    void handleSubmit();
                }}
                className="w-[80%] mx-auto py-10 text-white"
            >
                <div className="mb-6">
                    <label
                        htmlFor="title"
                        className="block text-xl font-semibold"
                    >
                        Title
                    </label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={edited.title}
                        onChange={(e) => handleChange('title', e.target.value)}
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="publisher"
                        className="block text-xl font-semibold"
                    >
                        Publisher
                    </label>
                    <input
                        type="text"
                        id="publisher"
                        name="publisher"
                        value={edited.publisher}
                        onChange={(e) =>
                            handleChange('publisher', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="publish_date"
                        className="block text-xl font-semibold"
                    >
                        Publish Date
                    </label>
                    <input
                        type="date"
                        id="publish_date"
                        name="publish_date"
                        value={edited.publish_date}
                        onChange={(e) =>
                            handleChange('publish_date', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="link"
                        className="block text-xl font-semibold"
                    >
                        Link
                    </label>
                    <input
                        type="url"
                        id="link"
                        name="link"
                        value={edited.link}
                        onChange={(e) => handleChange('link', e.target.value)}
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>

                <div className="flex justify-between mt-10">
                    <button
                        type="submit"
                        className="px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-xl font-normal"
                    >
                        Save Press Entry
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate('/press', { replace: true })}
                        className="px-5 py-2 bg-gray-500 text-white rounded-lg text-center text-xl font-normal"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
