import { Header } from '../../components';
import { FaChevronCircleRight, FaArrowRight } from 'react-icons/fa';
import { useState } from 'react';
import { Link } from 'react-router-dom';
const allianceimg = '/tech-consulting/alliance_logo.png';
const constitutionality = '/tech-consulting/constitutionality.png';
const stanfordLogo = '/tech-consulting/stanfordLogo.png';

const slideOne = (
    <div className="inline-block">
        <div className="flex flex-col bg-base-gray md:flex-row md:h-[600px]">
            <img
                src={allianceimg}
                alt="Alliance for Higher Education website image"
                className="object-contain bg-black md:w-1/3"
            />
            <div className="xl:my-auto p-5">
                <h3 className="text-UL-green text-xl text-center md:text-3xl md:text-left xl:text-4xl">
                    ALLIANCE FOR HIGHER <br /> EDUCATION WEBSITE
                </h3>
                <p className="text-white text-md font-light whitespace-normal md:text-xl mt-4 pr-10">
                    The Alliance for Higher Education in Prison mobilizes talent
                    and resources toward expanding higher education and academic
                    reentry support services to incarcerated and formerly
                    incarcerated individuals. Entering the next phase of their
                    mission and looking for a new website, the Alliance
                    contracted with Unlocked Labs to determine how to best
                    communicate their action plan.
                    <br />
                    <br />
                    Our engineering manager and our justice-involved apprentices
                    worked in conjunction with the Alliance to
                    <span className="text-UL-green font-bold">
                        {' '}
                        recommend a revamped website design and business model
                        to support higher education initiatives in prison.
                    </span>
                </p>
            </div>
        </div>
    </div>
);

const slideTwo = (
    <div className="inline-block">
        <div className="flex flex-col bg-base-gray md:h-[600px] md:flex-row">
            <div
                className="bg-[#007861] relative w-full h-full
            h-[350px]
            lg:w-[490px] lg:h-[600px]
            xl:h-[600px] xl:w-[723px]"
            >
                <div className="text-xs lg:text-base p-5 xl:pl-10 flex flex-col">
                    <div
                        className="bg-[#990008] whitespace-normal p-5 w-[70%]
                    lg:w-[90%]
                    xl:w-1/2 xl:top-[20%]
                    "
                    >
                        <h3 className="text-white font-bold">
                            Original narrative
                        </h3>
                        <p className="text-white">
                            {' '}
                            <span className="text-[#00CCF5] font-bold">
                                Lucy Johnson{' '}
                            </span>
                            reported that a{' '}
                            <span className="text-[#F5AB00] font-bold">
                                Black
                            </span>{' '}
                            male with
                            <span className="text-[#A4F9AE] font-bold">
                                {' '}
                                brown hair{' '}
                            </span>
                            wearing a black jacket assaulted her in{' '}
                            <span className="text-[#D4B3FF] font-bold">
                                Midtown
                            </span>
                            , next to{' '}
                            <span className="text-[#00CCF5] font-bold">
                                Johnson
                            </span>
                            &apos;s home. She reported the incident to
                            <span className="text-[#00D624] font-bold">
                                {' '}
                                Officer Lee
                            </span>
                            .
                        </p>
                    </div>
                    <div
                        className="bg-[#990008] mt-5 whitespace-normal p-5 w-[70%] top-44
                    lg:w-[90%] lg:top-56
                    xl:top-[55%] xl:w-1/2
                    "
                    >
                        <h3 className="text-white font-bold">
                            Automatically redacted narrative
                        </h3>
                        <p className="text-white">
                            {' '}
                            <span className="text-[#00CCF5] font-bold">
                                [Victim 1]{' '}
                            </span>
                            reported that a{' '}
                            <span className="text-[#F5AB00] font-bold">
                                [race]
                            </span>{' '}
                            male with
                            <span className="text-[#A4F9AE] font-bold">
                                {' '}
                                [hair color]{' '}
                            </span>
                            wearing a black jacket assaulted her in{' '}
                            <span className="text-[#D4B3FF] font-bold">
                                [neighborhood]
                            </span>
                            , next to{' '}
                            <span className="text-[#00CCF5] font-bold">
                                [Victim 1]
                            </span>
                            &apos;s home. She reported the incident to
                            <span className="text-[#00D624] font-bold">
                                {' '}
                                [Officer 1]
                            </span>
                            .
                        </p>
                    </div>
                </div>
                <img
                    src={stanfordLogo}
                    alt="Stanford University Logo"
                    className="absolute
                    object-scale-down max-lg:w-[70px] bottom-1/3 right-3
                    lg:max-xl:object-scale-down lg:max-xl:h-[150px] lg:max-xl:bottom-5 lg:max-xl:left-1/4
                    xl:right-16 xl:top-[30%]"
                />
            </div>
            <div className="md:my-auto md:w-3/4 xl:w-[55%]">
                <h3
                    className="text-UL-green text-xl p-5 max-md:w-[75%] max-md:mx-auto text-center
                md:text-3xl md:text-left
                xl:text-4xl
                "
                >
                    STANFORD UNIVERSITY
                    <br />
                    RACE BLIND AI ALGORITHM
                </h3>
                <p
                    className="text-white text-md font-light p-5 whitespace-normal
                md:text-xl
                xl:w-[95%]
                "
                >
                    Stanford University&apos;s Computational Policy Lab has
                    created a “blind charging” algorithm that automatically
                    removes race-related details from crime reports, thereby
                    minimizing the impact of racial bias in prosecutorial
                    charging decisions. <br /> <br />
                    The lab has hired Unlocked Labs to{' '}
                    <span className="text-UL-green font-bold">
                        {' '}
                        employ formerly incarcerated developers to build their
                        website in addition to writing test-harnesses and
                        training the AI algorithm itself.
                    </span>
                    This initiative will aid prosecutors nationwide in adhering
                    to the increasing prevalence of race-blind mandates in the
                    evolving legal landscape.
                </p>
            </div>
        </div>
    </div>
);

const slideThree = (
    <div className="inline-block">
        <div className="flex flex-col bg-base-gray h-auto md:flex-row md:h-[600px]">
            <img
                src={constitutionality}
                alt="Constitution image"
                className="md:w-1/3"
            ></img>
            <div className="md:my-auto">
                <h3
                    className="text-UL-green text-xl p-5 max-md:w-[75%] max-md:mx-auto text-center
                md:text-3xl md:text-left
                xl:text-4xl
                "
                >
                    CONSTITUTIONALITY BOT:
                    <br />A NLP MODEL{' '}
                </h3>
                <p
                    className="text-white text-md font-light p-5 whitespace-normal
                md:text-xl
                xl:w-[95%]
                "
                >
                    Graduates of our coding education program supported Raj
                    Jaladi in building a natural language processing prototype
                    that predicts whether a legal case or situation involves a
                    violation of an article or amendment of the U.S.
                    Constitution. <br /> <br />
                    Our team members worked with Raj to{' '}
                    <span className="text-UL-green font-bold">
                        {' '}
                        build out a user interface that allows individuals to
                        input text and generate a response from an AI chat bot
                        trained on case summaries of the Supreme Court.{' '}
                    </span>
                    The web-UI consists of a simple text input, where a user can
                    type in a question and/or topic summary, and the response is
                    displayed below the text box.
                </p>
            </div>
        </div>
    </div>
);

function Carousel() {
    const [currSlide, setCurrSlide] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const slidesArray = [slideOne, slideTwo, slideThree];
    const transitionDuration = 500;

    const shiftCarousel = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setCurrSlide((prev) =>
            prev === slidesArray.length - 1 ? 0 : prev + 1
        );
        setTimeout(() => setIsTransitioning(false), transitionDuration);
    };

    return (
        <div className="py-5 md:py-20 relative flex overflow-hidden">
            <div className="whitespace-nowrap scroll-smooth">
                <div
                    className="flex transition-transform ease-in-out duration-700"
                    style={{
                        transform: `translateX(${currSlide * -33.33}%)`
                    }}
                >
                    {slidesArray.map((slide, index) => (
                        <div key={index} className="md:h-[600px] w-screen">
                            {slide}
                        </div>
                    ))}
                </div>
            </div>
            <FaChevronCircleRight
                className="absolute right-3 text-UL-green top-1/2 transform -translate-y-1/2 cursor-pointer"
                size={40}
                onClick={shiftCarousel}
            />
        </div>
    );
}

export default function TechConsulting() {
    return (
        <>
            <Header title="Tech Consulting" />
            <div className="bg-black">
                <section>
                    <p
                        className="
                        text-white text-2xl text-center p-[7%]
                        md:text-5xl md:leading-[60px] md:py-20
                        "
                    >
                        Unlocked Labs{' '}
                        <span className="text-UL-green font-bold">
                            trains and hires
                        </span>{' '}
                        returning citizens to do contract software development
                        work upon release.
                    </p>
                    <div
                        className="border-[1px] border-UL-green w-3/4 mx-auto
                    md:border-2 md:w-2/3
                    xl:w-1/2
                    "
                    ></div>
                    <p
                        className="text-white text-center text-base p-10 pt-0 font-extralight
                    md:text-3xl md:px-20 md:pt-20 md:pb-20
                    xl:px-[14%] xl:text-2xl
                    "
                    >
                        Those formerly incarcerated, despite their training and
                        credentials, face barriers in securing high-paying,
                        career-advancing jobs. Our approach enables talented
                        individuals to access upwardly mobile employment upon
                        release.
                        <br /> <br /> Our team offers a unique perspective to
                        help develop your project, having an insider&apos;s
                        understanding of the current challenges associated with
                        corrections. We&apos;ll work with you every step of the
                        way to customize a solution that suits your specific
                        needs.
                    </p>
                </section>
                <section className="pb-20">
                    <div
                        className="hide-scrollbar
                    border-[0.5px] border-UL-green mx-auto
                    md:border-[1px] md:w-1/2
                    xl:border-2 xl:w-1/3"
                    >
                        <h2
                            className="
                        text-UL-green text-2xl py-5 px-10
                        md:text-4xl md:py-10
                        xl:text-5xl xl:py-16  text-center"
                        >
                            Our Work
                        </h2>
                    </div>
                    <Carousel />
                    <div
                        className="border-[0.5px] border-UL-green mx-auto
                    md:border-[1px] md:w-1/2
                    xl:border-2 xl:w-[43%] xl:py-10"
                    >
                        <h2
                            className="
                        text-UL-green text-xl px-10 text-center py-5
                        md:text-2xl
                        xl:text-3xl"
                        >
                            Have a project you need help with?
                        </h2>
                        <p
                            className="text-white text-center text-md
                        md:text-lg
                        xl:text-xl
                        "
                        >
                            Reach out with the details, and we&apos;ll <br />
                            collaborate to craft your desired outcome!
                        </p>
                        <Link
                            to="/contact"
                            className="flex justify-center py-5 
                        md:text-lg
                        xl:text-xl"
                        >
                            <p className="text-white font-bold">Contact Us</p>
                            <FaArrowRight className="text-white self-center" />
                        </Link>
                    </div>
                </section>
            </div>
        </>
    );
}
