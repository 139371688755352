import { Header } from '../../components';
import { Link } from 'react-router-dom';
export default function OurStory() {
    return (
        <div className="bg-black overflow-auto">
            <Header title="Our Story" />
            <section>
                <p
                    className="
                text-white text-2xl text-center p-[7%]
                md:text-5xl md:leading-[60px] md:py-20
                "
                >
                    Unlocked Labs has evolved from a project within a prison in
                    Potosi, Missouri, to a{' '}
                    <span className="text-UL-green font-bold">
                        nationwide movement.
                    </span>
                </p>
                <div
                    className="border-[1px] border-UL-green w-3/4 mx-auto
                md:border-2 md:w-2/3
                xl:w-1/2
                "
                ></div>
                <p
                    className="text-white text-center text-base p-10 font-extralight
                md:text-3xl md:px-20 md:pt-10 md:pb-20
                xl:px-[15%] xl:text-2xl
                "
                >
                    Since 2022, Unlocked Labs has been dedicated to enhancing
                    education in prisons and employing returning citizens upon
                    release, encouraging a collective re-imagining of a more
                    equitable justice system. This is how we got to where we are
                    today.
                </p>
            </section>
            <section className="hidden lg:flex lg:flex-cols-3 lg:justify-center">
                {/* left column */}
                <div>
                    {/* Education against the odds */}
                    <div className="mt-[300px] w-96">
                        <h2 className="text-left text-white text-2xl font-bold">
                            Education Against the Odds
                        </h2>
                        <div className="border-UL-green border-2 p-3">
                            <span className="text-white text-xl font-normal ">
                                They both maximized their circumstances,{' '}
                            </span>
                            <span className="text-UL-green text-xl font-bold ">
                                spending years tutoring others
                            </span>
                            <span className="text-white text-xl font-normal ">
                                {' '}
                                to pass their GED/Hi-Set exams and{' '}
                            </span>
                            <span className="text-UL-green text-xl font-bold ">
                                organizing victim empathy and anger management
                                courses
                            </span>
                            <span className="text-white text-xl font-normal ">
                                {' '}
                                for the community. Despite residing in a
                                facility without access to formal education for
                                over 25 years, they were determined to make a
                                positive impact.
                            </span>
                        </div>
                        <img
                            src={'/our-story/codingInCell.jpg'}
                            alt="Chris Santillian coding with computer on bed in cell."
                        />
                    </div>
                    {/* Establishing Unlocked Labs */}
                    <div className="mt-[150px] w-96">
                        <h2 className="text-left text-white text-2xl font-bold">
                            Establishing Unlocked Labs
                        </h2>
                        <div className="border-UL-green border-2 p-3">
                            <span className="text-white text-xl font-normal ">
                                Their lives took a turn when a Supreme Court
                                declared life sentences for juveniles
                                unconstitutional. Released in early 2022,
                                Jessica and Chris teamed up with Haley Shoaf,
                                who shared and helped them expand upon their
                                vision, to form{' '}
                            </span>
                            <span className="text-UL-green text-xl font-bold ">
                                Unlocked Labs,{' '}
                            </span>
                            <span className="text-white text-xl font-normal ">
                                a non-profit dedicated to consulting and
                                developing products addressing challenges within
                                the justice system.
                            </span>
                        </div>
                        <img
                            src={'/our-story/chrisJessHaley.jpg'}
                            alt="Left to right: Chris Santillian, Haley Shoaf, and Jessica Hicklin smile wearing matching Unlocked Labs logoed shirts."
                        />
                    </div>
                </div>
                {/* middle timeline */}
                <div className="flex flex-col justify-center hidden lg:block">
                    <div className="flex flex-row mt-[100px]">
                        <div className="md:w-20 xl:w-32 h-0" />
                        <div className="w-20 h-20 rounded-full border-8 border-UL-green" />
                        <div className="md:w-20 xl:w-32 h-0 border-4 border-UL-green my-auto" />
                    </div>
                    <div className="w-0 h-[278px] border-4 border-UL-green mx-auto" />
                    <div className="flex flex-row">
                        <div className="md:w-20 xl:w-32 h-0 border-4 border-UL-green my-auto" />
                        <div className="w-20 h-20 rounded-full border-8 border-UL-green" />
                        <div className="md:w-20 xl:w-32 h-0 " />
                    </div>
                    <div className="w-0 h-[278px] border-4 border-UL-green mx-auto" />
                    <div className="flex flex-row">
                        <div className="md:w-20 xl:w-32 h-0 " />
                        <div className="w-20 h-20 rounded-full border-8 border-UL-green" />
                        <div className="md:w-20 xl:w-32 h-0 border-4 border-UL-green my-auto" />
                    </div>
                    <div className="w-0 h-[278px] border-4 border-UL-green mx-auto" />
                    <div className="flex flex-row">
                        <div className="md:w-20 xl:w-32 h-0 border-4 border-UL-green my-auto" />
                        <div className="w-20 h-20 rounded-full border-8 border-UL-green" />
                        <div className="md:w-20 xl:w-32 h-0 " />
                    </div>
                    <div className="w-0 h-[278px] border-4 border-UL-green mx-auto" />
                    <div className="flex flex-row">
                        <div className="md:w-20 xl:w-32 h-0 " />
                        <div className="w-20 h-20 rounded-full border-8 border-UL-green" />
                        <div className="md:w-20 xl:w-32 h-0 border-4 border-UL-green my-auto" />
                    </div>
                    <div className="w-0 h-[278px] border-4 border-UL-green mx-auto" />
                    <div className="flex flex-row">
                        <div className="md:w-20 xl:w-32 h-0" />
                        <div className="w-20 h-20 rounded-full border-8 border-UL-green" />
                        <div className="md:w-20 xl:w-32 h-0 " />
                    </div>
                    <div className="w-0 h-32 border-4 border-UL-green mx-auto" />
                </div>
                {/* right column */}
                <div>
                    {/* Young Beginnings */}
                    <div className="w-96">
                        <h2 className="text-left text-white text-2xl font-bold">
                            Young Beginnings
                        </h2>
                        <div className="border-UL-green border-2 p-3 py-5">
                            <span className="text-white text-xl font-normal">
                                Co-Founders{' '}
                            </span>
                            <span className="text-UL-green text-xl font-bold">
                                Jessica Hicklin
                            </span>
                            <span className="text-white text-xl font-normal ">
                                {' '}
                                and{' '}
                            </span>
                            <span className="text-UL-green text-xl font-bold ">
                                Chris Santillian
                            </span>
                            <span className="text-white text-xl font-normal ">
                                {' '}
                                met at Potosi Correction Center before both of
                                their 18th birthdays. They were sentenced to
                                life without parole and told they would die
                                behind bars.
                            </span>
                        </div>
                        <img
                            src={'/our-story/jessChris.jpg'}
                            alt="Jessica Hicklin in focus in background and Chris Santillian out of focus in foreground."
                        />
                    </div>
                    {/* Coding for Change */}
                    <div className="mt-[175px] w-96">
                        <h2 className="text-left text-white text-2xl font-bold">
                            Coding for Change
                        </h2>
                        <div className="border-UL-green border-2 p-3 py-5">
                            <span className="text-white text-xl font-normal ">
                                With limited resources, Jessica and Chris both{' '}
                            </span>
                            <span className="text-UL-green text-xl font-bold ">
                                taught themselves how to code without the
                                internet
                            </span>
                            <span className="text-white text-xl font-normal ">
                                . They dreamed of a day when they could create a
                                solution for ways to track rehabilitation and
                                educational programs on the inside.
                            </span>
                        </div>
                        <img
                            src={'/our-story/jessBooks.jpg'}
                            alt="Jessica Hicklin laughing holding stack of 15 computer science textbooks."
                        />
                    </div>
                    {/* UnlockED: A Vision Realized */}
                    <div className="mt-[175px] w-96">
                        <h2 className="text-left text-white text-2xl font-bold">
                            UnlockED: A Vision Realized
                        </h2>
                        <div className="border-UL-green border-2 p-3">
                            <span className="text-white text-xl font-normal ]">
                                Teaming up with external partners, they
                                developed{' '}
                            </span>
                            <span className="text-UL-green text-xl font-bold ]">
                                UnlockEd, a non-profit, open source education
                                access and program management system
                            </span>
                            <span className="text-white text-xl font-normal ]">
                                {' '}
                                providing free education to incarcerated
                                individuals across the country, fulfilling a
                                long time dream, and bringing the project full
                                circle.
                            </span>
                        </div>
                        <img
                            src={'/our-story/haleyJess.jpg'}
                            alt="Jessica Hicklin and Haley Shoaf look at computers during computer lab deployment."
                        />
                    </div>
                </div>
            </section>
            <section className="flex flex-col gap-20 w-3/4 mx-auto lg:hidden">
                {/* Young Beginnings */}
                <div className="">
                    <h2 className="text-left text-white md:text-2xl font-bold">
                        Young Beginnings
                    </h2>
                    <div className="border-UL-green border-2 p-3 py-5 md:text-xl">
                        <span className="text-white font-normal">
                            Co-Founders{' '}
                        </span>
                        <span className="text-UL-green font-bold">
                            Jessica Hicklin
                        </span>
                        <span className="text-white font-normal "> and </span>
                        <span className="text-UL-green font-bold ">
                            Chris Santillian
                        </span>
                        <span className="text-white font-normal ">
                            {' '}
                            met at Potosi Correction Center before both of their
                            18th birthdays. They were sentenced to life without
                            parole and told they would die behind bars.
                        </span>
                    </div>
                    <img
                        src={'/our-story/jessChris.jpg'}
                        alt="Jessica Hicklin in focus in background and Chris Santillian out of focus in foreground."
                    />
                </div>
                {/* Education against the odds */}
                <div className="">
                    <h2 className="text-left text-white md:text-2xl font-bold">
                        Education Against the Odds
                    </h2>
                    <div className="border-UL-green border-2 p-3 md:text-xl">
                        <span className="text-white font-normal ">
                            They both maximized their circumstances,{' '}
                        </span>
                        <span className="text-UL-green font-bold ">
                            spending years tutoring others
                        </span>
                        <span className="text-white font-normal ">
                            {' '}
                            to pass their GED/Hi-Set exams and{' '}
                        </span>
                        <span className="text-UL-green font-bold ">
                            organizing victim empathy and anger management
                            courses
                        </span>
                        <span className="text-white font-normal ">
                            {' '}
                            for the community. Despite residing in a facility
                            without access to formal education for over 25
                            years, they were determined to make a positive
                            impact.
                        </span>
                    </div>
                    <img
                        src={'/our-story/jessChris.jpg'}
                        alt="Chris Santillian coding with computer on bed in cell."
                    />
                </div>
                {/* Coding for Change */}
                <div className="">
                    <h2 className="text-left text-white md:text-2xl font-bold">
                        Coding for Change
                    </h2>
                    <div className="border-UL-green border-2 p-3 py-5 md:text-xl">
                        <span className="text-white font-normal ">
                            With limited resources, Jessica and Chris both{' '}
                        </span>
                        <span className="text-UL-green font-bold ">
                            taught themselves how to code without the internet
                        </span>
                        <span className="text-white font-normal ">
                            . They dreamed of a day when they could create a
                            solution for ways to track rehabilitation and
                            educational programs on the inside.
                        </span>
                    </div>
                    <img
                        src={'/our-story/jessBooks.jpg'}
                        alt="Jessica Hicklin laughing holding stack of 15 computer science textbooks."
                    />
                </div>
                {/* Establishing Unlocked Labs */}
                <div className="">
                    <h2 className="text-left text-white md:text-2xl font-bold">
                        Establishing Unlocked Labs
                    </h2>
                    <div className="border-UL-green border-2 p-3 md:text-xl">
                        <span className="text-white font-normal ">
                            Their lives took a turn when a Supreme Court
                            declared life sentences for juveniles
                            unconstitutional. Released in early 2022, Jessica
                            and Chris teamed up with Haley Shoaf, who shared and
                            helped them expand upon their vision, to form{' '}
                        </span>
                        <span className="text-UL-green font-bold ">
                            Unlocked Labs,{' '}
                        </span>
                        <span className="text-white font-normal ">
                            a non-profit dedicated to consulting and developing
                            products addressing challenges within the justice
                            system.
                        </span>
                    </div>
                    <img
                        src={'/our-story/chrisJessHaley.jpg'}
                        alt="Left to right: Chris Santillian, Haley Shoaf, and Jessica Hicklin smile wearing matching Unlocked Labs logoed shirts."
                    />
                </div>
                {/* UnlockED: A Vision Realized */}
                <div className="">
                    <h2 className="text-left text-white md:text-2xl font-bold">
                        UnlockED: A Vision Realized
                    </h2>
                    <div className="border-UL-green border-2 p-3 md:text-xl">
                        <span className="text-white font-normal ]">
                            Teaming up with external partners, they developed{' '}
                        </span>
                        <span className="text-UL-green font-bold ]">
                            UnlockEd, a non-profit, open source education access
                            and program management system
                        </span>
                        <span className="text-white font-normal ]">
                            {' '}
                            providing free education to incarcerated individuals
                            across the country, fulfilling a long time dream,
                            and bringing the project full circle.
                        </span>
                    </div>
                    <img
                        src={'/our-story/haleyJess.jpg'}
                        alt="Jessica Hicklin and Haley Shoaf look at computers during computer lab deployment."
                    />
                </div>
            </section>
            {/* The birth of a movement */}
            <div className="mt-20 w-3/4 lg:mt-0 lg:w-[917px] mx-auto border-UL-green border-2">
                <div className="p-5">
                    <h2 className="text-center text-white md:text-2xl font-bold">
                        The Birth of a Movement
                    </h2>
                    <div className="text-center pt-2 md:text-xl">
                        <span className="text-white font-normal ">
                            Now, over 70% of our team consists of individuals
                            with justice-impacted backgrounds, including
                            seasoned coders who have thrived at Unlocked Labs
                            upon their release.{' '}
                        </span>
                        <span className="text-UL-green font-bold ">
                            Since 2022, Unlocked Labs has excelled in expanding
                            access to education nationwide, providing access to
                            training and building public infrastructure in
                            numerous facilities across multiple states.{' '}
                        </span>
                    </div>
                </div>
            </div>
            <div className="h-24"></div>
            <section className="bg-base-gray flex flex-col gap-10 justify-center py-24">
                <h2 className="text-center text-white text-2xl mx-10">
                    Learn more about how we&apos;re building better justice
                    system from the inside, out.
                </h2>
                <div className="grid grid-col md:grid-cols-2 mx-auto gap-20 text-2xl text-center">
                    <Link
                        className="border-UL-green border-2 p-16 text-UL-green"
                        to="/team"
                    >
                        Our Team
                    </Link>
                    <Link
                        className="border-UL-green border-2 p-16 text-UL-green"
                        to="/mission"
                    >
                        Our Mission
                    </Link>
                </div>
            </section>
        </div>
    );
}
