import { FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { SupportersCarousel } from '../components/SupportersCarousel';
import { Link } from 'react-router-dom';

export default function Home() {
    return (
        <div className="bg-black overflow-auto">
            <section className="flex flex-col sm:h-screen relative xl:w-[90%] xl:mx-auto">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className=""
                >
                    <img
                        className="bg-cover md:w-1/2 mx-auto sm:mx-0 xl:w-[45%]"
                        src={'/atif.png'}
                        alt="Man looking right off the screen"
                    />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.75 }}
                    className="text-center mt-0 md:text-right md:absolute md:top-[100px] md:right-0 md:w-[64%] xl:w-[60%] xl:top-[120px]"
                >
                    <h1
                        className="
          text-4xl text-white p-2 pt-10 sm:pt-2 sm:text-3xl lg:text-5xl lg:leading-[60px] xl:text-6xl xl:leading-[70px]"
                    >
                        A Better <br className="sm:hidden" />
                        Justice System, <br /> Built from the{' '}
                        <br className="sm:hidden" />
                        <span className="text-UL-green"> Inside, Out.</span>
                    </h1>
                    <h2 className="text-white text-center text-md p-4 md:text-right md:text-xl md:leading-[30px] md:ml-24 xl:ml-8 xl:text-2xl xl:leading-[40px]">
                        Unlocked Labs is a team of justice-impacted
                        technologists designing and building solutions that make
                        corrections evidence-based and data-driven.
                    </h2>
                </motion.div>
            </section>
            <section className="px-10">
                <h2
                    className="text-UL-green text-xl text-center pb-5
        lg:text-4xl lg:p-10
        xl:text-5xl xl:pt-[150px]
        "
                >
                    We&apos;re revolutionizing <br className="md:hidden" />{' '}
                    prison education.
                </h2>
                <div
                    className="border-[1px] border-UL-green w-3/4 mx-auto
            md:border-2 md:w-2/3
            xl:w-1/2
            "
                ></div>
                <p
                    className="text-white text-sm text-center pt-5 mx-auto
        lg:text-xl lg:p-10
        xl:text-2xl xl:w-[1087px] xl:leading-loose
        "
                >
                    We collaborate with the Department of Corrections,
                    educators, universities, and individuals with lived
                    experience to increase access to education, gather data, and
                    achieve successful outcomes, collectively working towards
                    reducing recidivism rates and building a safer, more just
                    society.
                </p>
            </section>
            <section
                className="px-10
      xl:flex xl:flex-cols-2 xl:pt-[150px]
      "
            >
                <div className="xl:flex xl:flex-col xl:w-[40%]">
                    <h2
                        className="text-UL-green text-xl text-center pt-10
            lg:text-4xl
            xl:text-right xl:text-5xl xl:pt-0"
                    >
                        Our Approach
                    </h2>
                    <p
                        className="text-white text-sm text-center pt-3
            lg:text-xl lg:max-xl:px-20
            xl:text-right xl:text-xl xl:ml-[144px] xl:leading-loose"
                    >
                        We develop innovative products to bridge educational
                        gaps in correctional facilities, ensuring consistent
                        protocols and gathering valuable data, while also
                        preparing justice-impacted individuals for sustainable
                        employment.
                    </p>
                </div>
                <ul
                    className="grid grid-rows-3 py-10 gap-5
        lg:max-xl:p-[100px]
        xl:px-20 xl:pt-0 xl:pb-24 xl:w-[60%]"
                >
                    <Link to="/unlocked">
                        <li
                            className="border-[0.5px] border-UL-green text-UL-green text-center p-3 h-full
            lg:text-3xl
            xl:hover:bg-base-gray
            "
                        >
                            UnlockEd
                            <p
                                className="text-white text-xs font-extralight p-5
                lg:text-2xl
                xl:text-xl xl:font-light
                "
                            >
                                UnlockEd is a platform that enables corrections
                                departments to expand educational offerings,
                                collect data, track outcomes, and provide
                                learning experiences for incarcerated students.
                            </p>
                        </li>
                    </Link>
                    <Link to="/tech-consulting">
                        <li
                            className="border-[0.5px] border-UL-green text-UL-green text-center p-3 h-full
            lg:text-3xl
            xl:hover:bg-base-gray
            "
                        >
                            Consulting Services
                            <p
                                className="text-white text-xs font-extralight p-5
                lg:text-2xl
                xl:text-xl xl:font-light
                "
                            >
                                We offer consulting services for a spectrum of
                                projects. We train and hire returning citizens
                                to fulfill contract work, providing effective
                                solutions enriched by their insider perspective.
                            </p>
                        </li>
                    </Link>
                    <Link to="/coding-education">
                        <li
                            className="border-[0.5px] border-UL-green text-UL-green text-center p-3 h-full
            lg:text-3xl
            xl:hover:bg-base-gray
            "
                        >
                            Coding Education Program
                            <p
                                className="text-white text-xs font-extralight p-5
                lg:text-2xl
                xl:text-xl xl:font-light
                "
                            >
                                We provide an offline Intro to Web Development
                                that can be administered inside of prison on
                                Department of Corrections-approved laptops.
                            </p>
                        </li>
                    </Link>
                </ul>
            </section>
            <section
                className="bg-base-gray flex flex-col px-10 py-24
        lg:flex-row lg:gap-10"
            >
                <div className="lg:w-1/2 lg:my-auto">
                    <h2
                        className="text-2xl text-UL-green text-center
            lg:text-4xl lg:text-right lg:leading-[50px]
            xl:text-5xl xl:leading-[60px]
            "
                    >
                        Education & employment reduce recidivism.
                    </h2>
                    <p
                        className="text-sm text-white text-center py-5
            lg:text-right lg:text-xl
            "
                    >
                        The United States faces an alarming 83% recidivism rate.
                        We&apos;re on a mission to drive these numbers down.
                    </p>
                    <a
                        className="flex flex-row mx-auto justify-end lg:text-xl text-white xl:hover:text-UL-green"
                        href="/mission"
                    >
                        <p className="hidden lg:block">
                            Learn more about our why
                        </p>{' '}
                        <FaArrowRight className="self-center hidden lg:block" />{' '}
                    </a>
                </div>
                <img
                    className="lg:w-1/2"
                    src={'/graph.png'}
                    alt="Graph showing the decrease in recidivism as education increases."
                />
                <Link
                    className="flex text-white mx-auto pt-5 lg:hidden xl:hover:text-UL-green"
                    to="/mission"
                >
                    Learn more about our why{' '}
                    <FaArrowRight className="self-center" />
                </Link>
            </section>
            <section className="grid md:grid-cols-2 text-center md:text-left px-10 md:py-10">
                <img
                    className=""
                    src={'/rabbit.png'}
                    alt="Man looking off to left of screen"
                />
                <div className="flex flex-col my-auto xl:mt-24">
                    <h2
                        className="text-2xl text-UL-green text-center
            lg:text-4xl lg:text-right lg:leading-[50px]
            xl:text-5xl xl:leading-[60px] xl:w-[85%]"
                    >
                        Effective tools built from <br /> lived experiences.
                    </h2>
                    <p
                        className="text-sm text-white text-center py-5
            lg:text-right lg:text-xl xl:w-[78%] xl:ml-[10%]
          "
                    >
                        Over 70% of our team has first hand experience living or
                        working in the corrections system. This personal
                        understanding of the challenges allows us to build
                        effective tools, addressing the real needs of end-users.
                    </p>
                    <Link
                        className="flex flex-row mx-auto lg:mr-0 lg:text-xl xl:mr-[15%] text-white xl:hover:text-UL-green"
                        to="/team"
                    >
                        <p className="">Learn more about our team</p>{' '}
                        <FaArrowRight className="self-center" />{' '}
                    </Link>
                </div>
            </section>
            <div className="py-10 md:pt-6">
                <div className="mx-auto w-3/4 h-[0px] border-[0.25vw] border-UL-green"></div>
                <SupportersCarousel />
                <div className="mx-auto w-3/4 h-[0px] border-[0.25vw] border-UL-green"></div>
            </div>
        </div>
    );
}
