export function Header({ title }: { title: string }) {
    return (
        <div
            className="
    border-[0.5px] border-UL-green w-2/3 -ml-1 
    md:border-[1px] md:w-1/2
    xl:border-2 xl:w-1/3"
        >
            <h1
                className="
        text-UL-green text-4xl p-5 
        md:text-6xl md:p-10
        xl:text-7xl xl:p-16 text-center"
            >
                {title}
            </h1>
        </div>
    );
}
