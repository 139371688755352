import { useState, useContext, useEffect } from 'react';
import { TeamMembers, Header } from '../../components';
import { AuthContext } from '../../components/authCtx';
import { Link } from 'react-router-dom';
import { Team } from '../../common';

export default function AboutUs() {
    const { isAuthenticated } = useContext(AuthContext);
    const [team, setTeam] = useState<Team[] | undefined>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/team', {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        accept: 'application/json'
                    }
                });
                if (response.ok) {
                    const jsonData = await response.json();
                    setTeam(jsonData as Team[]);
                }
            } catch (error) {
                console.error(error);
            }
        };
        void fetchData();
    }, []);

    const handleDeleteTeamMember = async (id: string) => {
        const resp = await fetch(`/api/team/${id}`, {
            method: 'DELETE',
            credentials: 'include'
        });
        if (resp.ok) {
            const updatedTeam = team?.filter((person) => person.id !== id);
            setTeam(updatedTeam);
        }
    };

    return (
        <div className="bg-black overflow-auto">
            <Header title="Our Team" />
            {isAuthenticated && (
                <>
                    <Link to="/team/new">
                        <button className="text-UL-green hover:border-UL-green bg-black py-2 px-16 text-sm font-semibold">
                            Add New Team Member
                        </button>
                    </Link>
                </>
            )}
            <section>
                <p
                    className="
                text-white text-2xl text-center p-[7%]
                md:text-5xl md:leading-[60px] md:pt-20 md:pb-0
                "
                >
                    We&apos;re{' '}
                    <span className="text-UL-green font-bold">
                        breaking the cycle
                    </span>
                    <br className="xl:hidden" /> of re-incarceration.
                </p>
                <p
                    className="text-white text-center text-base p-10 pt-0 font-extralight
                md:text-3xl md:px-20 md:pt-10 md:pb-20
                xl:px-[15%] xl:text-2xl
                "
                >
                    Over half our team has direct experience in corrections,
                    providing a real understanding of the issues and effective
                    solutions. As a close-knit team of passionate technologists,
                    our dedication lies in building a system that nurtures
                    individuals to reach their full potential, fostering a safer
                    and more just society for all.
                </p>
                <div
                    className="border-[1px] border-UL-green w-3/4 mx-auto
                md:border-2 md:w-2/3
                xl:w-1/2
                "
                ></div>
            </section>
            <TeamMembers
                team={team ?? []}
                handleDeleteTeamMember={handleDeleteTeamMember}
            />
            <div
                className="border-[1px] border-UL-green w-3/4 mx-auto
                md:border-2 md:w-2/3
                xl:w-1/2
                "
            ></div>
        </div>
    );
}
