export function Button({ title, href }: { title: string; href: string }) {
    return (
        <a
            href={href}
            className="bg-UL-green text-base-gray text-center text-md rounded-lg px-[5%] py-[2%]"
        >
            {title}
        </a>
    );
}
