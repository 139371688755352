export const stripeUrl = 'https://donate.stripe.com/fZe6qMacT9uB0eI9AA';

export interface IJob {
    title: string;
    id: string;
    category: string;
    location: string;
    overview: string;
    responsibilities: Array<string>;
    qualifications: Array<string>;
    [key: string]: string | Array<string>;
}

export interface Press {
    id: string;
    publish_date: string;
    title: string;
    publisher: string;
    link: string;
}

export interface ServerResponse<T> {
    data: T[];
    total?: number;
    last_page?: number;
}

export interface Team {
    id: string;
    photo: string;
    name: string;
    position: string;
}

export interface AppForm {
    first_name: string;
    last_name: string;
    email: string;
    message: string;
    resume: File | null;
    cover_letter?: File | null;
}
