'use client';
import { Header } from '../../components';
import { ContactForm } from '../../components/ContactForm';
import { useState } from 'react';

export default function Contact() {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState<string | null>(null);

    async function handleSubmit(formData: any) {
        setSubmitting(true);
        try {
            const encoded = new URLSearchParams(formData).toString();
            const response = await fetch('/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: encoded
            });
            if (response.ok) {
                setSubmitSuccess(true);
                console.log('success');
            } else {
                setSubmitError('An error occurred. Please try again later.');
            }
        } catch (error) {
            console.error(error);
            setSubmitError('An error occurred. Please try again later.');
        } finally {
            setSubmitting(false);
        }
    }

    if (submitSuccess) {
        return (
            <div className="bg-black overflow-auto">
                <div
                    className="
                    border-[0.5px] border-UL-green w-2/3 py-[10%] mx-auto bg-base-gray
                    md:border-[1px] md:w-1/2
                    xl:border-2 xl:w-2/3"
                >
                    <div className="text-white text-center">
                        <h2 className="text-2xl font-bold">
                            Thank you for contacting us!
                        </h2>
                        <p className="mt-4">
                            We will be sure to get back to you as soon as
                            possible.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-black overflow-auto">
            <Header title="Contact" />
            <div className="p-[10%] xl:p-[5%] mx-auto">
                <div className="mx-auto xl:w-3/4">
                    <h1
                        className="
                    text-white text-4xl text-center pb-10
                    md:text-6xl
                    "
                    >
                        Connect With Us:
                    </h1>
                    <div className="border-[0.1vw] border-UL-green w-[75%] mx-auto"></div>
                    <p
                        className="
                    text-center text-white font-light text-base py-10
                    md:text-3xl
                    "
                    >
                        Whether you&apos;re interested in hiring our developers,
                        bringing our programs or technology to your state, or
                        supporting our mission, we&apos;d love to hear from you!
                    </p>
                </div>
                <div className="mx-auto md:w-3/4">
                    <ContactForm
                        handleSubmit={handleSubmit}
                        submitting={submitting}
                    />
                    {submitError && (
                        <div className="text-red-500 text-center text-3xl font-bold">
                            {submitError}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
