import { motion } from 'framer-motion';

import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useEffect } from 'react'; // Import useEffect

export function ComputerSection() {
    // const [ref, controls] = useScroll();
    const controls = useAnimation();

    const [ref, computerInView] = useInView({ threshold: 0.4 });
    const [ref2, lineInView] = useInView({ threshold: 0.4 });

    useEffect(() => {
        if (computerInView) {
            controls.start('show');
        } else {
            controls.start('hidden');
        }
    }, [controls, computerInView]);

    useEffect(() => {
        if (lineInView) {
            controls.start('show2');
        } else {
            controls.start('hidden2');
        }
    }, [controls, lineInView]);

    return (
        <div className="container relative h-full w-full bg-black pt-[30px] md:pt-[10%]">
            <div className="flex flex-row justify-center items-center h-full md:gap-10">
                <motion.div
                    animate={controls}
                    variants={computerAnimation}
                    transition={{ duration: 1 }}
                    ref={ref}
                    className="relative w-1/3"
                >
                    <img
                        className="relative z-10 w-full"
                        src={'/computer-picture.png'}
                        alt="Desktop computer with Unlocked Labs portal on display"
                    />
                </motion.div>
                <motion.div
                    initial={{ x: '50%' }}
                    animate={{ x: '0%' }}
                    transition={{ duration: 0.5 }}
                    className="text-center font-bold text-md md:text-3xl leading-5 content-center"
                >
                    <span className="text-white">
                        We are building <br />
                    </span>
                    <span className="text-UL-green">education</span>
                    <span className="text-white"> and </span>
                    <span className="text-UL-green">
                        data tools
                        <br />
                    </span>
                    <span className="text-white"> to expand access to</span>
                    <span className="text-UL-green">
                        <br /> education in prisons.
                    </span>
                </motion.div>
            </div>
            <div className="hidden md:block" ref={ref2}>
                <div className="bottom-1/4 text-center text-white text-sm md:text-2xl font-bold leading-[3vw] pt-10">
                    Our open source technology allows states to:
                </div>
                <div className="flex flex-row mx-auto py-6 -space-x-1 w-3/4 xl:w-[64%]">
                    <div className="w-[5vw] h-[5vw] shrink-0 grow-0 rounded-full border-[0.75vw] border-UL-green" />
                    <div className="w-[50vw] h-[0px] border-[0.5vw] border-UL-green self-center" />
                    <div className="w-[5vw] h-[5vw] shrink-0 grow-0 rounded-full border-[0.75vw] border-UL-green" />
                    <div className="w-[50vw] h-[0px] border-[0.5vw] border-UL-green self-center" />
                    <div className="w-[5vw] h-[5vw] shrink-0 grow-0 rounded-full border-[0.75vw] border-UL-green" />
                    <div className="w-[50vw] h-[0px] border-[0.5vw] border-UL-green self-center" />
                    <div className="w-[5vw] h-[5vw] shrink-0 grow-0 rounded-full border-[0.75vw] border-UL-green" />
                </div>
                <motion.ul
                    className="flex flex-row justify-between text-center text-white font-regular text-xl w-[85%] xl:w-3/4 mx-auto"
                    variants={list}
                    animate={controls}
                >
                    <motion.li className="w-[20%]" variants={item}>
                        Support re-entry with student records of achievement{' '}
                    </motion.li>
                    <motion.li className="w-[20%]" variants={item}>
                        Increase access to education and rehabilitation programs
                    </motion.li>
                    <motion.li className="w-[20%]" variants={item}>
                        Properly implement parole, good time & earned credit
                        release policies{' '}
                    </motion.li>
                    <motion.li className="w-[20%]" variants={item}>
                        Prove with data what programs work
                    </motion.li>
                </motion.ul>
            </div>
            <div className="md:hidden">
                <div className="bottom-1/4 text-center text-white text-sm md:text-2xl font-bold leading-[3vw] pt-10">
                    Our open source technology allows states to:
                </div>
                <div className="flex flex-row w-[70%] mx-auto py-5">
                    <div className="flex flex-col mx-auto">
                        <div className="w-[5vw] h-[5vw] shrink-0 grow-0 rounded-full border-[0.75vw] border-UL-green" />
                        <div className="w-[0vw] h-[50px] border-[0.5vw] border-UL-green self-center" />
                        <div className="w-[5vw] h-[5vw] shrink-0 grow-0 rounded-full border-[0.75vw] border-UL-green" />
                        <div className="w-[0vw] h-[50px] border-[0.5vw] border-UL-green self-center" />
                        <div className="w-[5vw] h-[5vw] shrink-0 grow-0 rounded-full border-[0.75vw] border-UL-green" />
                        <div className="w-[0vw] h-[50px] border-[0.5vw] border-UL-green self-center" />
                        <div className="w-[5vw] h-[5vw] shrink-0 grow-0 rounded-full border-[0.75vw] border-UL-green" />
                    </div>
                    <motion.ul className="flex flex-col justify-between text-center text-white font-regular text-xs mx-auto px-2">
                        {/* // variants={list} animate={controls} */}
                        <motion.li className="" variants={item}>
                            Support re-entry with student records of achievement{' '}
                        </motion.li>
                        <motion.li className="" variants={item}>
                            Increase access to education and rehabilitation
                            programs
                        </motion.li>
                        <motion.li className="" variants={item}>
                            Properly implement parole, good time & earned credit
                            release policies{' '}
                        </motion.li>
                        <motion.li className="" variants={item}>
                            Prove with data what programs work
                        </motion.li>
                    </motion.ul>
                </div>
            </div>
        </div>
    );
}

const computerAnimation = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
};

const list = {
    hidden2: { opacity: 1, scale: 0 },
    show2: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.5,
            staggerChildren: 0.5
        }
    }
};

const item = {
    hidden2: { y: 20, opacity: 0 },
    show2: {
        y: 0,
        opacity: 1
    }
};
