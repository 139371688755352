import { useContext } from 'react';
import { AuthContext } from './authCtx';
import { Link } from 'react-router-dom';
import { Team } from '../common';

interface TeamMembersProps {
    team: Team[];
    handleDeleteTeamMember: (id: string) => void;
}

export function TeamMembers({
    team,
    handleDeleteTeamMember
}: TeamMembersProps) {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <div className="py-10">
            <div className="flex flex-wrap gap-5 justify-center px-5 text-white text-center text-sm md:px-20 md:text-xl md:gap-10 xl:px-72">
                {team?.map((person: Team, index: number) => (
                    <div
                        key={index}
                        className="flex flex-col items-center w-full md:w-1/3 lg:w-1/4 xl:w-1/4"
                    >
                        <div className="relative w-[150px] h-[150px] md:w-[200px] md:h-[200px]">
                            <img
                                className="rounded-[30px]"
                                src={person.photo}
                                alt={person.name}
                            />
                        </div>
                        {isAuthenticated && (
                            <>
                                <Link to={`/team/${person.id}/edit`}>
                                    <button className="text-UL-green hover:border-UL-green bg-black py-2 px-16 text-sm font-semibold">
                                        Edit Team Member
                                    </button>
                                </Link>
                                <button
                                    onClick={() =>
                                        handleDeleteTeamMember(person.id)
                                    }
                                    className="text-UL-green  hover:border-UL-green bg-black py-2 px-16 text-sm font-semibold"
                                >
                                    Delete
                                </button>
                            </>
                        )}
                        <h3 className="pt-3 pb-1 font-bold">{person.name}</h3>
                        <h4 className="break-words">{person.position}</h4>
                    </div>
                ))}
            </div>
        </div>
    );
}
