import { Header } from '../../components';
import { useContext, useEffect, useState } from 'react';
import { FaCuttlefish } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { IJob } from '../../common';
import { AuthContext } from '../../components/authCtx';

export default function Careers() {
    const [filter, setFilter] = useState<String>('All');
    const [jobs, setJobs] = useState<Array<IJob> | undefined>();
    const { isAuthenticated } = useContext(AuthContext);
    const handleDeleteJobPost = async (id: string) => {
        try {
            const resp = await fetch(`/api/careers/${id}`, {
                method: 'DELETE',
                credentials: 'include'
            });
            if (resp.ok) {
                const updatedJobs = jobs?.filter((job) => job.id !== id);
                setJobs(updatedJobs);
            }
        } catch {
            alert('Error deleting job post');
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/careers', {
                    method: 'GET'
                });
                if (response.ok) {
                    const jsonData = await response.json();
                    setJobs(jsonData);
                }
            } catch (error) {
                console.error(error);
            }
        };
        void fetchData();
    }, []);

    function JobCard({ job }: { job: IJob }) {
        const link = `/careers/${job.id}`;
        return (
            <div>
                {isAuthenticated && (
                    <>
                        <Link to={`/careers/${job.id}/edit`}>
                            <button className="text-UL-green hover:border-UL-green bg-black py-2 px-16 text-sm font-semibold">
                                Edit Job Listing
                            </button>
                        </Link>
                        <button
                            onClick={() => handleDeleteJobPost(job.id)}
                            className="text-UL-green  hover:border-UL-green bg-black py-2 px-16 text-sm font-semibold"
                        >
                            Delete Job Listing
                        </button>
                    </>
                )}
                <Link
                    className="flex flex-col md:flex-row md:flex-cols-2 justify-between border-[2px] border-UL-green p-5 md:p-16 hover:bg-base-gray"
                    to={link}
                >
                    <h3 className="text-UL-green  md:text-2xl my-auto md:w-3/4">
                        {job.title}
                    </h3>
                    <div className="flex content-center gap-2 md:w-1/4 md:justify-end">
                        <p className="text-white md:text-2xl my-auto md:text-right">
                            {job.location}
                        </p>
                    </div>
                </Link>
            </div>
        );
    }

    function FilterJobs({ jobs }: { jobs: Array<IJob> }) {
        if (filter !== 'All') {
            const filteredJobs = jobs.filter((job) => job.category == filter);
            if (filteredJobs.length == 0) {
                return (
                    <div className="text-white text-center text-2xl font-bold p-10">
                        {' '}
                        No {filter.toLowerCase()} jobs open at this time.
                        <br /> Please check back again soon!
                    </div>
                );
            }
            return filteredJobs.map((jobListing: IJob) => {
                return (
                    <div
                        className=""
                        key={jobListing.title.concat(jobListing.id)}
                    >
                        <JobCard job={jobListing} />
                    </div>
                );
            });
        }
        if (jobs.length == 0) {
            return (
                <div className="text-white text-center text-2xl font-bold p-10">
                    {' '}
                    No jobs open at this time.
                    <br /> Please check back again soon!
                </div>
            );
        }
        return jobs.map((jobListing: IJob, idx: number) => {
            return (
                <div className="" key={idx}>
                    <JobCard job={jobListing} />
                </div>
            );
        });
    }

    return (
        <div className="overflow-auto bg-black">
            <Header title="Careers" />
            <div>
                <section>
                    <p
                        className="
                    text-white text-2xl text-center p-10
                    md:p-20 md:text-5xl md:leading-[60px]
                    "
                    >
                        Join us as we build a better justice system,{' '}
                        <span className="text-UL-green font-bold">
                            <br />
                            from the inside, out.
                        </span>
                        <br className="xl:hidden" />
                    </p>
                </section>
                {isAuthenticated && (
                    <Link to="/careers/new">
                        <button className="text-UL-green hover:border-UL-green bg-black py-2 px-16 text-xl font-semibold">
                            Post a Job
                        </button>
                    </Link>
                )}
                <section className="pb-20 mx-auto w-[80%] flex flex-col gap-10">
                    <div className="text-white gap-5 hidden md:flex md:flex-row">
                        <label className="my-auto text-xl font-semibold">
                            Filters:{' '}
                        </label>
                        {[
                            'All',
                            'Apprenticeship',
                            'Coding Education',
                            'Engineering',
                            'Operations',
                            'Other'
                        ].map((filterName) => (
                            <button
                                key={filterName}
                                className={`border border-UL-green hover:border-base-gray p-4 px-7 ${filter == filterName ? 'bg-UL-green text-base-gray' : ''}`}
                                onClick={() => {
                                    setFilter(
                                        filterName == filter
                                            ? 'All'
                                            : filterName
                                    );
                                }}
                            >
                                {filterName}
                            </button>
                        ))}
                    </div>
                    {jobs ? (
                        <FilterJobs jobs={jobs} />
                    ) : (
                        <div className="h-full md:h-96 flex justify-center items-center">
                            <motion.div
                                animate={{ rotate: 1080 }}
                                transition={{ duration: 5, loop: Infinity }}
                                className="text-white h-30"
                            >
                                <FaCuttlefish className="text-6xl" />
                            </motion.div>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
}
