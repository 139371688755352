import { FormEvent, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { AppForm } from '../common';

export function ApplicationForm({
    jobTitle,
    category,
    handleSubmit,
    submitting
}: {
    jobTitle: string;
    category: string;
    handleSubmit: (formData: FormData) => Promise<void>;
    submitting: boolean;
}) {
    const [form, setForm] = useState<AppForm>({
        first_name: '',
        last_name: '',
        email: '',
        message: '',
        resume: null,
        cover_letter: null
    } as AppForm);
    const recaptcha = useRef<ReCAPTCHA>(null);
    const [captchaValue, setCaptchaValue] = useState('');

    const allowedFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    function handleResumeUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files && event.target.files[0];
        if (file) {
            if (!allowedFileTypes.includes(file.type)) {
                alert(
                    'Unsupported file type. Please upload a PDF or Word document.'
                );
                event.target.value = '';
                return;
            }
            if (file.size > MAX_FILE_SIZE) {
                alert(
                    'File is too big! Please keep the attachment under 2 MB.'
                );
                event.target.value = '';
                return;
            } else {
                setForm({ ...form, resume: file });
            }
        }
    }

    function handleCoverLetterUpload(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const file = event.target.files && event.target.files[0];
        if (file) {
            if (!allowedFileTypes.includes(file.type)) {
                alert(
                    'Unsupported file type. Please upload a PDF or Word document.'
                );
                event.target.value = '';
                return;
            }
            if (file.size > MAX_FILE_SIZE) {
                alert(
                    'File is too big! Please keep the attachment under 2 MB.'
                );
                event.target.value = '';
                return;
            } else {
                setForm({ ...form, cover_letter: file });
            }
        }
    }

    async function validateCaptcha(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!');
        } else {
            const res = await fetch('/api/recaptcha', {
                method: 'POST',
                body: JSON.stringify({ captchaValue }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await res.json();
            if (data.success) {
                sendFormInfo();
            } else {
                alert('reCAPTCHA validation failed!');
                if (recaptcha.current) {
                    recaptcha.current.reset();
                }
            }
        }
    }

    function onReCAPTCHAChange(value: string | null) {
        if (value) {
            setCaptchaValue(value);
        } else {
            setCaptchaValue('');
        }
    }

    function sendFormInfo() {
        let formData = new FormData();
        formData.append('name', form.first_name + ' ' + form.last_name);
        formData.append('from', form.email);
        formData.append('subject', category + ' Application - ' + jobTitle);
        formData.append('message', form.message);
        formData.append('job_title', jobTitle);
        if (form.resume !== null)
            formData.append('resume', form.resume, form.resume.name);
        if (form.cover_letter)
            formData.append(
                'cover-letter',
                form.cover_letter,
                form.cover_letter.name
            );
        handleSubmit(formData);
    }

    return (
        <form
            onSubmit={validateCaptcha}
            className="
            flex flex-col justify-center font-extralight text-white gap-2 w-3/4 mx-auto
            "
        >
            <label className="text-xl font-medium">First Name: *</label>
            <input
                name="first_name"
                className="border-[0.5px] border-white bg-transparent pl-3 py-3 text-xl
                md:border-[1px] md:max-xl:p-5
                "
                required
                placeholder="First Name"
                type="text"
                value={form.first_name}
                onChange={handleChange}
            />
            <label className="text-xl font-medium">Last Name: *</label>
            <input
                name="last_name"
                className="border-[0.5px] border-white bg-transparent pl-3 py-3 text-xl
                md:border-[1px] md:max-xl:p-5
                "
                required
                placeholder="Last Name"
                type="text"
                value={form.last_name}
                onChange={handleChange}
            />
            <label className="text-xl font-medium">Email: *</label>
            <input
                name="email"
                className="border-[0.5px] border-white bg-transparent pl-3 py-3 text-xl
                md:border-[1px] md:max-xl:p-5
                xl:w-full
                "
                required
                placeholder="Email"
                type="email"
                value={form.email}
                onChange={handleChange}
            />
            <label className="text-xl font-medium">
                How did you hear about us? *
            </label>
            <textarea
                name="message"
                className="border-[0.5px] border-white bg-transparent pl-3 py-3 text-xl h-40 w-full mx-auto
                placeholder:mt-0
                md:border-[1px]
                xl:w-full
                "
                required
                placeholder="Type here..."
                value={form.message}
                onChange={handleChange}
            />
            <label className="text-xl font-medium">Resume: *</label>
            <input
                type="file"
                id="resume"
                name="resume"
                accept=".pdf, .doc, .docx"
                required
                onChange={handleResumeUpload}
            />
            <label className="text-xl font-medium">
                Cover Letter (optional):
            </label>
            <input
                type="file"
                id="coverLetter"
                name="coverLetter"
                accept=".pdf, .doc, .docx"
                onChange={handleCoverLetterUpload}
            />
            <ReCAPTCHA
                ref={recaptcha}
                sitekey="6LflNnMpAAAAAI8H7-mXg694kifXQZkFbe5fOIFX"
                onChange={onReCAPTCHAChange}
                className="mx-auto p-4"
            />
            <div className="pt-2 mx-auto">
                <button
                    type="submit"
                    className="border-[0.5px] border-base-gray bg-UL-green py-2 px-16 text-xl text-base-gray font-semibold md:border-[1px] md:max-xl:text-4xl md:px-30 xl:px-40"
                    disabled={submitting}
                >
                    {submitting ? 'Submitting...' : 'Submit'}
                </button>
            </div>
        </form>
    );
}
