import React, {
    createContext,
    Dispatch,
    useState,
    useEffect,
    SetStateAction
} from 'react';

export interface AuthProps {
    isAuthenticated: boolean;
    setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthProps>({
    isAuthenticated: false,
    setIsAuthenticated: () => {}
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        const checkSession = async () => {
            try {
                const resp = await fetch('/api/session', {
                    method: 'GET',
                    credentials: 'include'
                });
                const data = await resp.json();
                if (data.success) {
                    setIsAuthenticated(true);
                }
            } catch {
                setIsAuthenticated(false);
            }
        };
        void checkSession();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                setIsAuthenticated
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
