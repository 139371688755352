import { Header } from '../../components';

export default function CodingEducation() {
    const benHelping = '/ben-helping-coding-ed.png';
    const graduation = '/graduation.png';
    const securebooks = '/securebooks.png';
    return (
        <div className="bg-black overflow-auto">
            <Header title="Coding Education" />
            <section>
                <p
                    className="
                text-white text-2xl text-center p-[7%]
                md:text-5xl md:leading-[60px] md:py-20
                "
                >
                    We provide{' '}
                    <span className="text-UL-green font-bold">
                        free, open-source education{' '}
                    </span>
                    to empower justice-impacted students.
                </p>
                <div
                    className="border-[1px] border-UL-green w-3/4 mx-auto
                md:border-2 md:w-2/3
                xl:w-1/2
                "
                ></div>
                <p
                    className="text-white text-center text-base p-10 pt-0 font-extralight
                md:text-3xl md:px-20 md:pt-10 md:pb-20
                xl:px-[15%] xl:text-2xl
                "
                >
                    In partnership with{' '}
                    <a
                        href="https://www.launchcode.org/"
                        className="hover:text-UL-green"
                    >
                        LaunchCode
                    </a>
                    , we operate a coding education program inside of multiple
                    facilities nationwide. We help incarcerated students embark
                    on their coding journey, catering to individuals of all
                    experience levels and providing the essential components
                    needed to start learning.
                </p>
            </section>
            <section
                className="
            xl:flex xl:flex-cols-2 xl:justify-between bg-base-gray 
            "
            >
                <div className="my-auto p-8 xl:w-[40%] mx-auto">
                    <h2
                        className="text-UL-green text-right text-xl pb-5 leading-loose
                    md:text-4xl
                    xl:text-5xl xl:mr-0 xl:leading-[70px]
                    "
                    >
                        Extensive coursework at your fingertips, no internet
                        necessary.
                    </h2>
                    <p
                        className="text-white text-right font-light text-sm leading-loose
                    md:text-xl
                    lg:text-2xl"
                    >
                        Our 6-month flagship program teaches object oriented
                        concepts, elevating learners&apos; understanding of
                        computer science. This framework provides the
                        foundational scaffolding essential for crafting
                        sophisticated web applications capable of seamlessly
                        storing data in a local database, positioning learners
                        to excel when pursuing opportunities in the field.
                    </p>
                </div>
                <div>
                    <img
                        src={benHelping}
                        alt="Instructor helping students understand coursework using securebooks."
                    />
                </div>
            </section>
            <section className="py-[10%] w-3/4 mx-auto flex flex-col gap-16">
                <h2
                    className="text-UL-green text-center text-xl leading-loose
                    md:text-4xl
                    xl:text-5xl xl:mr-0 xl:leading-[70px]
                    "
                >
                    Department of Corrections approved.
                </h2>
                <img
                    src={securebooks}
                    className="mx-auto"
                    alt="Securebook computers from a side view, top view, and straight on view."
                />
                <p
                    className="text-white text-center font-light text-sm leading-loose
                    md:text-xl
                    lg:text-2xl"
                >
                    This course is run in computer labs and/or with a Department
                    of Corrections approved laptop that includes a metal-free
                    keyboard, with no USB, camera or networking capabilities.
                </p>
            </section>
            <section
                className="
            xl:flex xl:flex-cols-2 xl:justify-between bg-base-gray 
            "
            >
                <div>
                    <img
                        src={graduation}
                        alt="Speech given at a coding education program prison in Missouri"
                    />
                </div>
                <div className="my-auto p-8 xl:w-[60%] mx-auto">
                    <h2
                        className="text-UL-green text-left text-xl pb-5 leading-loose
                    md:text-4xl
                    xl:text-5xl xl:mr-0 xl:leading-[70px]
                    "
                    >
                        Proof of enrollment for <br /> future success.
                    </h2>
                    <p
                        className="text-white text-left font-light text-sm leading-loose
                    md:text-xl
                    lg:text-2xl"
                    >
                        Those who complete the program on the inside will
                        receive a LaunchCode certification of completion and are
                        eligible for 8 credits at Washington University in St.
                        Louis along with other participating colleges to
                        complete the Intro to Computer Science requirements for
                        continued education. Since this program&apos;s
                        inception, multiple graduates have gone on to pursue
                        computer science as their careers post release.
                    </p>
                </div>
            </section>
            <div className="p-16"></div>
        </div>
    );
}
