import { Header } from '../../components';
import { motion } from 'framer-motion';
import { ServerResponse, Press } from '../../common';
import { useContext, useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaCuttlefish } from 'react-icons/fa';
import { AuthContext } from '../../components/authCtx';
import { Link } from 'react-router-dom';

export default function PressPage() {
    const [page, setPage] = useState<number>(1);
    const [cardInformation, setCardInformation] =
        useState<ServerResponse<Press>>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `/api/press?page=${page}`;
                const response = await fetch(url, {
                    method: 'GET'
                });
                if (response.ok) {
                    const jsonData =
                        (await response.json()) as ServerResponse<Press>;
                    setCardInformation(jsonData);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [page]);

    const handleDeletePressItem = async (id: string) => {
        try {
            const resp = await fetch(`/api/press/${id}`, {
                method: 'DELETE',
                credentials: 'include'
            });
            if (resp.ok) {
                const updatedPress = cardInformation?.data.filter(
                    (press) => press.id !== id
                );
                setCardInformation({
                    ...cardInformation,
                    data: updatedPress ?? []
                });
            }
        } catch {
            alert('Error deleting press item');
        }
    };

    function Card({ row }: { row: Press }) {
        const publishDate = new Date(row.publish_date);
        const date = publishDate.toDateString();
        const title = row.title;
        const publisher = row.publisher;
        const link = row.link;

        return (
            <div className="grid grid-col-2 content-between border-2 border-UL-green p-10">
                {isAuthenticated && (
                    <button
                        onClick={() => handleDeletePressItem(row.id)}
                        className="text-UL-green  border-base-gray hover:border-white bg-black py-2 px-16 text-sm font-semibold w-64"
                    >
                        Delete Press Entry
                    </button>
                )}
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <h2 className="text-UL-green text-xl sm:text-2xl md:text-3xl font-bold pb-5">
                        {title}
                    </h2>
                    <div className="flex flex-col gap-2 lg:flex-row lg:gap-0 justify-between">
                        <h3 className="text-UL-green text-xl">{publisher}</h3>
                        <p className="text-white text-xl">{date}</p>
                    </div>
                </a>
            </div>
        );
    }

    return (
        <div className="bg-black overflow-auto">
            <Header title="Press" />
            {isLoading ? (
                <div className="h-screen md:h-96 flex justify-center items-center">
                    <motion.div
                        animate={{ rotate: 1080 }}
                        transition={{ duration: 5, loop: Infinity }}
                        className="text-white h-30"
                    >
                        <FaCuttlefish className="text-6xl" />
                    </motion.div>
                </div>
            ) : (
                <div>
                    {isAuthenticated && (
                        <Link to="/press/new">
                            <button className="text-UL-green hover:border-white bg-base-gray py-2 px-16 text-sm font-semibold">
                                Add Press Entry
                            </button>
                        </Link>
                    )}
                    <div className="p-[10%] lg:p-[5%] mx-auto grid grid-row lg:grid-cols-2 lg:grid-rows-5 gap-10">
                        {cardInformation?.data.map((row: Press, index) => {
                            return <Card row={row} key={index} />;
                        })}
                    </div>
                </div>
            )}
            <div className="flex flex-row justify-center my-auto text-white text-xl gap-5">
                <FaAngleLeft
                    className={page === 1 ? 'my-auto text-black' : 'my-auto'}
                    onClick={() => {
                        if (page > 1) {
                            setPage(page - 1);
                        }
                    }}
                />
                <div className={page == 1 ? 'my-auto text-black' : 'my-auto'}>
                    {page - 1}
                </div>
                <div className="font-bold bg-base-gray px-2">{page}</div>
                <div
                    className={
                        cardInformation?.last_page === page
                            ? 'my-auto text-black'
                            : 'my-auto'
                    }
                >
                    {page + 1}
                </div>
                <FaAngleRight
                    className={
                        page == cardInformation?.last_page
                            ? 'my-auto text-black'
                            : 'my-auto'
                    }
                    onClick={() => {
                        if (page < (cardInformation?.last_page ?? 0)) {
                            setPage(page + 1);
                        }
                    }}
                />
            </div>
        </div>
    );
}
