import { useState, useEffect, useContext, FormEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from './authCtx';
import { Header } from '../components/Header';
import { motion } from 'framer-motion';
import { FaSpinner } from 'react-icons/fa';
import { IJob } from '../common';

export default function EditCareerForm() {
    const { id } = useParams();
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [edited, setEdited] = useState<IJob>({} as IJob);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/', { replace: true });
            return;
        }
        const fetchData = async () => {
            const resp = await fetch(`/api/careers/${id}`, {
                method: 'GET',
                credentials: 'include'
            });
            if (resp.ok) {
                const data = await resp.json();
                if (data.typeof === 'array') {
                    setEdited(data[0]);
                } else {
                    setEdited(data);
                }
            }
            setLoading(false);
        };
        void fetchData();
    }, []);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await fetch(`/api/careers/${id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(edited)
            });
            if (response.ok) {
                navigate(`/careers`);
                return;
            }
        } catch (error) {
            console.error('Error updating career:', error);
        }
        alert('Error updating career, please try again or report this error');
        navigate(`/careers`);
    };

    const handleChange = (name: keyof IJob, val: string) => {
        setEdited((prevCareer) => ({
            ...prevCareer,
            [name]: val
        }));
    };

    const handleArrayChange = (
        index: number,
        field: 'responsibilities' | 'qualifications',
        value: string
    ) => {
        setEdited((prevCareer) => ({
            ...prevCareer,
            [field]: prevCareer[field].map((item: string, idx: number) =>
                idx === index ? value : item
            )
        }));
    };

    const handleAddItem = (field: keyof IJob) => {
        setEdited((prevCareer) => ({
            ...prevCareer,
            [field]: [...prevCareer[field], '']
        }));
    };

    const handleRemoveItem = (
        index: number,
        field: 'responsibilities' | 'qualifications'
    ) => {
        setEdited((prevCareer) => ({
            ...prevCareer,
            [field]: prevCareer[field].filter((_, idx: number) => idx !== index)
        }));
    };

    if (loading) {
        return (
            <div className="bg-black h-screen flex justify-center items-center">
                <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ duration: 2, loop: Infinity }}
                >
                    <FaSpinner className="text-white text-6xl" />
                </motion.div>
            </div>
        );
    }

    if (!edited) {
        return (
            <div className="bg-black h-screen flex justify-center items-center">
                <p className="text-white text-2xl">Career not found.</p>
            </div>
        );
    }

    return (
        <div className="bg-black min-h-screen">
            <Header title="Edit Job Listing" />
            <form
                onSubmit={handleSubmit}
                className="w-[80%] mx-auto py-10 text-white"
            >
                <div className="mb-6">
                    <label
                        htmlFor="title"
                        className="block text-xl font-semibold"
                    >
                        Title
                    </label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={edited.title}
                        onChange={(e) => handleChange('title', e.target.value)}
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="category"
                        className="block text-xl font-semibold"
                    >
                        Category
                    </label>
                    <select
                        id="category"
                        name="category"
                        value={edited.category}
                        onChange={(e) =>
                            handleChange('category', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    >
                        <option value="">Select a category</option>
                        <option value="Apprenticeship">Apprenticeship</option>
                        <option value="Coding Education">
                            Coding Education
                        </option>
                        <option value="Engineering">Engineering</option>
                        <option value="Operations">Operations</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="location"
                        className="block text-xl font-semibold"
                    >
                        Location
                    </label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={edited.location}
                        onChange={(e) =>
                            handleChange('location', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="overview"
                        className="block text-xl font-semibold"
                    >
                        Overview
                    </label>
                    <textarea
                        id="overview"
                        name="overview"
                        value={edited.overview}
                        onChange={(e) =>
                            handleChange('overview', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        rows={5}
                        required
                    ></textarea>
                </div>

                <div className="mb-6">
                    <label className="block text-xl font-semibold">
                        Responsibilities
                    </label>
                    {edited.responsibilities?.map((item, index) => (
                        <div key={index} className="flex items-center mt-2">
                            <input
                                type="text"
                                value={item}
                                onChange={(e) =>
                                    handleArrayChange(
                                        index,
                                        'responsibilities',
                                        e.target.value
                                    )
                                }
                                className="w-full p-3 bg-base-gray text-white rounded"
                                required
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    handleRemoveItem(index, 'responsibilities')
                                }
                                className="ml-2 text-red-500"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={() => handleAddItem('responsibilities')}
                        className="mt-3 px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-xl font-normal"
                    >
                        Add Responsibility
                    </button>
                </div>

                <div className="mb-6">
                    <label className="block text-xl font-semibold">
                        Qualifications
                    </label>
                    {edited.qualifications.map((item, index) => (
                        <div key={index} className="flex items-center mt-2">
                            <input
                                type="text"
                                value={item}
                                onChange={(e) =>
                                    handleArrayChange(
                                        index,
                                        'qualifications',
                                        e.target.value
                                    )
                                }
                                className="w-full p-3 bg-base-gray text-white rounded"
                                required
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    handleRemoveItem(index, 'qualifications')
                                }
                                className="ml-2 text-red-500"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={() => handleAddItem('qualifications')}
                        className="mt-3 px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-xl font-normal"
                    >
                        Add Qualification
                    </button>
                </div>

                <div className="flex justify-between mt-10">
                    <button
                        type="submit"
                        className="px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-xl font-normal"
                    >
                        Save Changes
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate('/careers', { replace: true })}
                        className="px-5 py-2 bg-gray-500 text-white rounded-lg text-center text-xl font-normal"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
