import { Header } from '../../components';

export default function UnlockEd() {
    const checkbox = '/checkbox.png';
    const computerFromBehind = '/computerFromBehind.png';
    const githubLogo = '/github.png';
    return (
        <div className="bg-black overflow-auto">
            <Header title="UnlockEd" />
            <section>
                <p
                    className="
                text-white text-2xl text-center p-[7%]
                md:text-5xl md:leading-[60px] md:py-20
                "
                >
                    UnlockEd enables the justice system to transition towards{' '}
                    <span className="text-UL-green font-bold">
                        evidence-based, data-driven{' '}
                    </span>
                    practices.
                </p>
                <div
                    className="border-[1px] border-UL-green w-3/4 mx-auto
                md:border-2 md:w-2/3
                xl:w-1/2
                "
                ></div>
            </section>
            <section
                className="flex flex-col p-10 gap-10 mx-auto justify-center
            md:p-20
            xl:flex-row 
            "
            >
                <h2
                    className="text-UL-green text-2xl text-center font-base justify-self-end
                md:text-5xl 
                xl:text-right xl:leading-[70px] xl:p-0 xl:w-[500px]
                "
                >
                    Corrections is hard.
                    <br /> A lack of tools makes it harder.
                </h2>
                <p
                    className="text-white text-center py-10 font-extralight
                md:text-2xl
                xl:w-[593px] xl:text-left xl:py-0
                "
                >
                    In corrections, the current challenges lie in the lack of
                    effective solutions for deploying education access and
                    centralizing data. The lack of data and tools makes
                    implementing evidence-based practices nearly impossible,
                    resulting in unsafe, overcrowded prisons. <br /> <br />
                    That’s why were building UnlockEd, an education platform
                    that enables corrections departments to expand educational
                    offerings, track outcomes, and provide learning experiences
                    for incarcerated students.
                </p>
            </section>
            <section
                className="
            xl:flex xl:flex-cols-2 bg-base-gray 
            "
            >
                <div className="my-auto p-8 xl:p-0">
                    <h2
                        className="text-UL-green text-center text-lg pb-5
                    md:text-3xl
                    xl:text-4xl xl:w-3/4 xl:mx-auto
                    "
                    >
                        We empower you to collect the evidence needed for:
                    </h2>
                    <div className="flex flex-row">
                        <ul className="grid grid-rows-6 gap-3 mx-auto font-extralight">
                            <div
                                className="flex flex-row content-center my-auto gap-2
                            "
                            >
                                <img
                                    src={checkbox}
                                    alt="checkbox"
                                    className="object-contain w-[30px]
                                md:w-[50px]
                                "
                                />
                                <li
                                    className="text-white text-base my-auto
                                md:text-2xl
                                "
                                >
                                    Refining programming models
                                </li>
                            </div>
                            <div className="flex flex-row content-center my-auto gap-2">
                                <img
                                    src={checkbox}
                                    alt="checkbox"
                                    className="object-contain w-[30px]
                                md:w-[50px]"
                                />
                                <li
                                    className="text-white text-base my-auto
                                md:text-2xl
                                "
                                >
                                    Reducing corrections budgets
                                </li>
                            </div>
                            <div className="flex flex-row content-center my-auto gap-2">
                                <img
                                    src={checkbox}
                                    alt="checkbox"
                                    className="object-contain w-[30px]
                                md:w-[50px]"
                                />
                                <li
                                    className="text-white text-base my-auto
                                md:text-2xl
                                "
                                >
                                    Effectively deploying programs with{' '}
                                    <br className="hidden lg:block" /> limited
                                    staff
                                </li>
                            </div>
                            <div className="flex flex-row content-center my-auto gap-2">
                                <img
                                    src={checkbox}
                                    alt="checkbox"
                                    className="object-contain w-[30px]
                                md:w-[50px]"
                                />
                                <li
                                    className="text-white text-base my-auto
                                md:text-2xl
                                "
                                >
                                    Making informed paroling decisions
                                </li>
                            </div>
                            <div className="flex flex-row content-center my-auto gap-2">
                                <img
                                    src={checkbox}
                                    alt="checkbox"
                                    className="object-contain w-[30px]
                                md:w-[50px]"
                                />
                                <li
                                    className="text-white text-base my-auto
                                md:text-2xl
                                "
                                >
                                    Establishing best practices
                                </li>
                            </div>
                            <div className="flex flex-row content-center my-auto gap-2">
                                <img
                                    src={checkbox}
                                    alt="checkbox"
                                    className="object-contain w-[30px]
                                md:w-[50px]"
                                />
                                <li
                                    className="text-white text-base my-auto
                                md:text-2xl
                                "
                                >
                                    Ensuring compliance with program{' '}
                                    <br className="hidden lg:block" />
                                    mandates and good time laws
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div>
                    <img
                        src={computerFromBehind}
                        alt="Two individuals checking UnlockEd from behind"
                    />
                </div>
            </section>
            <section
                className="flex flex-col gap-5 p-10 pb-20 justify-center xl:py-36
            xl:flex-row"
            >
                <h2
                    className="text-UL-green text-center text-2xl font-base my-auto
                lg:text-5xl
                xl:text-right xl:w-[315px] xl:text-5xl
                "
                >
                    Partners, Not Vendors.
                </h2>
                <div className="hidden xl:block border-[1px] border-UL-green h-[150px] w-0" />
                <p
                    className="text-white text-center font-extralight my-auto
                lg:text-2xl md:max-lg:p-20 md:max-lg:pt-0
                xl:text-left xl:text-2xl xl:w-[850px]
                "
                >
                    At Unlocked Labs, we believe in collaboration. We work as
                    your partners, not just vendors, to deploy tools tailored to
                    fit seamlessly into your corrections environment.{' '}
                    <br className="md:hidden" /> Join us in transforming the
                    landscape of corrections through innovation.
                </p>
            </section>
            <section className="flex flex-col p-10 gap-10 mx-auto justify-center items-center bg-base-gray">
                <h2 className="text-UL-green text-2xl text-center font-base md:text-5xl">
                    Open Source and Built in Public
                </h2>
                <p className="text-white text-center font-extralight md:text-2xl max-w-4xl">
                    UnlockEd is an open-source project built collaboratively by
                    our dedicated team. We believe in the power of transparency
                    and community contribution. Our codebase is publicly
                    available, and we welcome developers to join us in improving
                    and evolving the platform.
                </p>
                <a
                    href="https://github.com/UnlockedLabs/UnlockEdv2"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center mt-5 text-UL-green hover:text-white"
                >
                    <img
                        src={githubLogo}
                        alt="GitHub Logo"
                        className="w-8 h-8 mr-2"
                    />
                    <span className="text-xl font-semibold">
                        Star on GitHub
                    </span>
                </a>
            </section>
            <div
                className="border-[1px] border-UL-green w-3/4 mx-auto
                md:border-2 md:w-2/3
                xl:w-1/2
                "
            ></div>
        </div>
    );
}
