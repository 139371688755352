import { ComputerSection, Header } from '../../components';
import portrait from '/portrait-2.png';

export default function Mission() {
    return (
        <div className="bg-black overflow-auto">
            <Header title="Our Mission" />
            <section>
                <p
                    className="
                text-white text-2xl text-center p-[7%]
                md:text-5xl md:leading-[60px] md:py-20
                "
                >
                    The U.S. spends over{' '}
                    <span className="text-UL-green font-bold">
                        <br className="md:hidden" />
                        $81 billion per year
                    </span>
                    <br className="md:hidden" /> on incarceration, yet 68% of
                    returning citizens are rearrested within 3 years.
                </p>
                <div
                    className="border-[1px] border-UL-green w-3/4 mx-auto
                md:border-2 md:w-2/3
                xl:w-1/2
                "
                ></div>
                <p
                    className="text-white text-center text-base p-10 pt-0 font-extralight
                md:text-3xl md:px-20 md:pt-10 md:pb-20
                xl:px-[15%] xl:text-2xl
                "
                >
                    Mass incarceration is unsustainable. Education and
                    employment are the most effective solution in reducing
                    recidivism rates, yet very few of these opportunities exist
                    for those who are incarcerated. We&apos;re on a mission to
                    build a better justice system from the inside out.
                </p>
            </section>
            <section
                className="grid grid-rows-3 justify-center mx-auto gap-10 text-white text-center text-sm
            md:text-2xl md:py-10
            xl:grid-cols-3 xl:grid-rows-1 xl:text-2xl xl:w-2/3
            "
            >
                <div className="">
                    <span className="text-UL-green text-6xl md:text-9xl xl:text-8xl">
                        60%
                    </span>
                    <br /> of incarcerated adults <br /> are considered <br />{' '}
                    <span className="text-UL-green">
                        functionally illiterate.
                    </span>
                </div>
                <div>
                    <span className="text-UL-green text-6xl md:text-9xl xl:text-8xl">
                        68%
                    </span>
                    <br /> of incarcerated adults <br /> do not have a <br />{' '}
                    <span className="text-UL-green">high school diploma.</span>
                </div>
                <div>
                    <span className="text-UL-green text-6xl md:text-9xl xl:text-8xl">
                        94%
                    </span>
                    <br /> of incarcerated adults <br /> do not have access{' '}
                    <br />{' '}
                    <span className="text-UL-green">to higher education.</span>
                </div>
            </section>
            <ComputerSection />
            <div className="flex w-3/4 mx-auto py-10">
                <img
                    className="relative z-0 object-scale-down w-1/2"
                    src={portrait}
                    alt="Man looking at the camera"
                />
                <div
                    className="right-0 text-white text-right text-base my-auto
                md:text-4xl
                xl:text-4xl
                "
                >
                    We&apos;re catalyzing reform at{' '}
                    <span className="text-UL-green">the human level</span>,
                    <br /> to help catalyze reform at <br />{' '}
                    <span className="text-UL-green">a system level.</span>
                </div>
            </div>
        </div>
    );
}
