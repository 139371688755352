import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { IJob } from '../common';

export default function AddCareerForm() {
    const [edited, setEdited] = useState<IJob>({
        id: '',
        title: '',
        category: '',
        location: '',
        overview: '',
        responsibilities: [''],
        qualifications: ['']
    });
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            const resp = await fetch('/api/careers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(edited)
            });
            if (resp.ok) {
                navigate('/careers', { replace: true });
            }
        } catch {
            alert('Error adding job post');
        }
    };

    const handleChange = (name: keyof IJob, val: string) => {
        setEdited((prevCareer) => ({
            ...prevCareer,
            [name]: val
        }));
    };

    const handleArrayChange = (
        index: number,
        field: 'responsibilities' | 'qualifications',
        value: string
    ) => {
        setEdited((prevCareer) => ({
            ...prevCareer,
            [field]: prevCareer[field].map((item: string, idx: number) =>
                idx === index ? value : item
            )
        }));
    };

    const handleAddItem = (field: keyof IJob) => {
        setEdited((prevCareer) => ({
            ...prevCareer,
            [field]: [...prevCareer[field], '']
        }));
    };

    const handleRemoveItem = (
        index: number,
        field: 'responsibilities' | 'qualifications'
    ) => {
        setEdited((prevCareer) => ({
            ...prevCareer,
            [field]: prevCareer[field].filter((_, idx: number) => idx !== index)
        }));
    };

    return (
        <div className="bg-black min-h-screen">
            <Header title="Add New Job Listing" />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    void handleSubmit();
                }}
                className="w-[80%] mx-auto py-10 text-white"
            >
                <div className="mb-6">
                    <label
                        htmlFor="title"
                        className="block text-xl font-semibold"
                    >
                        Title
                    </label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={edited.title}
                        onChange={(e) => handleChange('title', e.target.value)}
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="category"
                        className="block text-xl font-semibold"
                    >
                        Category
                    </label>
                    <select
                        id="category"
                        name="category"
                        value={edited.category}
                        onChange={(e) =>
                            handleChange('category', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    >
                        <option value="">Select a category</option>
                        <option value="Apprenticeship">Apprenticeship</option>
                        <option value="Coding Education">
                            Coding Education
                        </option>
                        <option value="Engineering">Engineering</option>
                        <option value="Operations">Operations</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="location"
                        className="block text-xl font-semibold"
                    >
                        Location
                    </label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={edited.location}
                        onChange={(e) =>
                            handleChange('location', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="overview"
                        className="block text-xl font-semibold"
                    >
                        Overview
                    </label>
                    <textarea
                        id="overview"
                        name="overview"
                        value={edited.overview}
                        onChange={(e) =>
                            handleChange('overview', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        rows={5}
                        required
                    ></textarea>
                </div>

                <div className="mb-6">
                    <label className="block text-xl font-semibold">
                        Responsibilities
                    </label>
                    {edited.responsibilities?.map((item, index) => (
                        <div key={index} className="flex items-center mt-2">
                            <input
                                type="text"
                                value={item}
                                onChange={(e) =>
                                    handleArrayChange(
                                        index,
                                        'responsibilities',
                                        e.target.value
                                    )
                                }
                                className="w-full p-3 bg-base-gray text-white rounded"
                                required
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    handleRemoveItem(index, 'responsibilities')
                                }
                                className="ml-2 text-red-500"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={() => handleAddItem('responsibilities')}
                        className="mt-3 px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-xl font-normal"
                    >
                        Add Responsibility
                    </button>
                </div>

                <div className="mb-6">
                    <label className="block text-xl font-semibold">
                        Qualifications
                    </label>
                    {edited.qualifications.map((item, index) => (
                        <div key={index} className="flex items-center mt-2">
                            <input
                                type="text"
                                value={item}
                                onChange={(e) =>
                                    handleArrayChange(
                                        index,
                                        'qualifications',
                                        e.target.value
                                    )
                                }
                                className="w-full p-3 bg-base-gray text-white rounded"
                                required
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    handleRemoveItem(index, 'qualifications')
                                }
                                className="ml-2 text-red-500"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={() => handleAddItem('qualifications')}
                        className="mt-3 px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-xl font-normal"
                    >
                        Add Qualification
                    </button>
                </div>

                <div className="flex justify-between mt-10">
                    <button
                        type="submit"
                        className="px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-xl font-normal"
                    >
                        Save Career Post
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate('/careers', { replace: true })}
                        className="px-5 py-2 bg-gray-500 text-white rounded-lg text-center text-xl font-normal"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
