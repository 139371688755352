import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header';

interface NewTeamMember {
    name: string;
    position: string;
}

interface Photo {
    image_preview: string;
    image_file: File;
}

export default function AddTeamMember() {
    const [photo, setPhoto] = useState<Photo>();
    const [teamMember, setTeamMember] = useState<NewTeamMember>({
        name: '',
        position: ''
    });
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const formData = new FormData();
        const name = teamMember.name.split(' ')[0];
        formData.append('name', teamMember.name);
        formData.append('position', teamMember.position);
        if (photo && photo.image_file) {
            formData.append('photo', photo.image_file, `${name}.png`);
        }
        try {
            const resp = await fetch('/api/team', {
                method: 'POST',
                credentials: 'include',
                body: formData
            });
            if (resp.ok) {
                navigate('/team', { replace: true });
            } else {
                alert('Error adding press entry');
            }
        } catch {
            alert('Error adding press entry');
        }
    };

    const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (files && files.length > 0) {
            const image_file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhoto({
                    image_preview: reader.result as string,
                    image_file: image_file
                });
            };
            reader.readAsDataURL(image_file);
        }
    };

    const handleChange = (name: keyof NewTeamMember, val: string) => {
        setTeamMember((prevPress) => ({
            ...prevPress,
            [name]: val
        }));
    };

    return (
        <div className="bg-black min-h-screen">
            <Header title="Add New Team Member" />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    void handleSubmit();
                }}
                className="w-[80%] mx-auto py-10 text-white"
            >
                {photo && photo.image_preview && (
                    <img
                        src={photo.image_preview}
                        className="rounded-[30px] w-64 h-64"
                        alt="preview"
                    />
                )}

                <div className="mb-6">
                    <label
                        htmlFor="name"
                        className="block text-xl font-semibold"
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={teamMember.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="position"
                        className="block text-xl font-semibold"
                    >
                        Position
                    </label>
                    <input
                        type="text"
                        id="position"
                        name="position"
                        value={teamMember.position}
                        onChange={(e) =>
                            handleChange('position', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="link"
                        className="block text-xl font-semibold"
                    >
                        Select Photo
                    </label>
                    <input
                        type="file"
                        id="photo"
                        name="photo"
                        onChange={handleImagePreview}
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="flex justify-between mt-10">
                    <button
                        type="submit"
                        className="px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-xl font-normal"
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate('/team', { replace: true })}
                        className="px-5 py-2 bg-gray-500 text-white rounded-lg text-center text-xl font-normal"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
